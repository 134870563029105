import { IActionContext } from '@msdyn365-commerce/core';

type StoreCookieValue = {
    storeName: string;
    storeId: string;
    attributeId: number;
};

const STORE_COOKIE_NAME = 'FILTER_STORES';

const setStoreCookie = (actionContext: IActionContext, cookieValue: StoreCookieValue) =>
    _getCookiesFromContext(actionContext).set(STORE_COOKIE_NAME, cookieValue);

const removeStoreCookie = (actionContext: IActionContext) => _getCookiesFromContext(actionContext).remove(STORE_COOKIE_NAME);

const getStoreCookie = (actionContext: IActionContext): StoreCookieValue | undefined =>
    _getCookiesFromContext(actionContext).get<StoreCookieValue>(STORE_COOKIE_NAME).value;

const isStoreCookieSet = (actionContext: IActionContext): boolean =>
    _getCookiesFromContext(actionContext).get(STORE_COOKIE_NAME).status === 'FOUND';

const _getCookiesFromContext = (actionContext: IActionContext) => actionContext.requestContext.cookies;

export { StoreCookieValue, setStoreCookie, getStoreCookie, isStoreCookieSet, removeStoreCookie };
