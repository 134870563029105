import { IActionContext } from '@msdyn365-commerce/core';
import { AttributeDataType, ProductRefinerSource, ProductRefinerValue } from '@msdyn365-commerce/retail-proxy';
import { getStoreCookie } from '../utilities/customer-experience-helper';

const getStoreRefiner = (ctx: IActionContext): ProductRefinerValue | undefined => {
    const storeCookie = getStoreCookie(ctx);
    if (storeCookie) {
        return {
            RefinerRecordId: storeCookie.attributeId,
            DataTypeValue: AttributeDataType.Text,
            LeftValueBoundString: storeCookie.storeId,
            RightValueBoundString: storeCookie.storeId,
            RefinerSourceValue: ProductRefinerSource.Attribute
        };
    }
    return undefined;
};

export default getStoreRefiner;
